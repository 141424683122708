<template>
  <Layout>
    <div class="bsat__container webinar">
      <router-link
        text
        to="/training-in-bioeconomy-sectors"
        class="bsat__btn dark-text px-0 font-weight-bold"
      >
        <v-icon left color="dark">mdi-keyboard-backspace</v-icon>
        {{ $t("backToWebinars") }}
      </router-link>
      <v-img
        contain
        class="img my-sm-15"
        src="@/assets/icons/webinars/bioBasedMaterials.jpg"
      ></v-img>
      <div class="bsat__title">{{ $t("title") }}</div>
      <div class="bsat__paragraph">{{ $t("intro") }}</div>
      <div>
        <a :href="agenda" target="_blank" class="mt-4">
          {{ $t("agenda") }}
        </a>
      </div>

      <v-list class="py-0">
        <Webinar
          v-for="(webinar, i) in webinars"
          :key="i"
          v-bind="{ ...webinar }"
        ></Webinar>
      </v-list>
    </div>
  </Layout>
</template>

<i18n>
{
	"en": {
		"backToWebinars": "Back",
		"intro": "POWER4BIO organised a training webinar series about bio-based materials. In two thematic training webinars, experts from the POWER4BIO consortium, related bioeconomy projects, companies from the bio-based industrial sector, research institutes and international agencies gave insights about the current status of bio-based materials at EU level, innovative practices and keys for successful business cases, value chains, sustainability, funding opportunities and supporting policies. All webinars were free of charge and open to everyone.",
        "title": "Training on Bio Based Materials",
        "agenda": "DOWNLOAD AGENDA OF POWER4BIO WEBINAR SEREIS ON BIO-BASED MATERIALS"
	}
}
</i18n>

<script>
import Layout from "@/layouts/default";
import Webinar from "@/components/Webinar";
export default {
  name: "BioChemicals",
  metaInfo: {
    title: "Training on Bio Based Materials"
  },
  components: {
    Layout,
    Webinar
  },
  data() {
    return {
      agenda: require("@/assets/files/webinars/biobasedMaterials/Agenda.pdf")
        .default,
      webinars: [
        {
          multiplePresentations: true,
          intro:
            "In this first POWER4BIO training webinar on bio-based materials, we provided a general overview of bio-based materials in Europe and presented good practice cases. We also introduced the POWER4BIO Bioeconomy Strategy Accelerator Toolkit (BSAT), an online platform for guiding decision-makers to review or develop regional bioeconomy strategies.",
          title: "Training webinar 1: Introduction to biomaterials",
          speakers: [
            {
              name:
                "Katalin Kalai (Bay Zoltan Nonprofit Ltd.): Welcome and introduction",
              presentation: require("@/assets/files/webinars/biobasedMaterials/BBM1.pdf")
                .default
            },
            {
              name:
                "Ignacio Martinez (CIRCE, coordinator of POWER4BIO): POWER4BIO’s Bioeconomy strategy accelerator toolkit",
              presentation: ""
            },
            {
              name:
                "Kornel Mateffy (Bay Zoltan Nonprofit Ltd.): Introduction to biomass valorisation for biomaterials",
              presentation: require("@/assets/files/webinars/biobasedMaterials/BBM3.pdf")
                .default
            },
            {
              name:
                "Philippe Willems (Orineo): Biomaterials success stories: Orineo, a matter of Impacting Authenticity",
              presentation: require("@/assets/files/webinars/biobasedMaterials/BBM4.pdf")
                .default
            }
          ],
          embed: "https://www.youtube.com/embed/fEG9XM4Sk4M"
        },
        {
          multiplePresentations: true,
          intro:
            "In the second POWER4BIO training webinar on bio-based materials, we tackled the needs, limitations and opportunities of policy instruments for bio-based materials. We took a closer look to policy instruments that facilitate and set requirements to the development of potential value chains. We also presented results and good policy examples from task 4.2 of the POWER4BIO project: public policies and regulation to support bio-based business models at regional level. We also took practical aspects to implement financial Instruments into account and present further good practice cases.",
          title:
            "Training webinar 2: Biomaterials: financing, supporting policies, good practice cases",
          speakers: [
            {
              name:
                "Luigi Amati (META Group): How to finance biomaterials and their value chains using European Structural and Investment Funds",
              presentation: ""
            },
            {
              name:
                "Berien Elbersen (Wageningen Environmental Research): Supporting policies for bioeconomy – focus on biomaterials",
              presentation: require("@/assets/files/webinars/biobasedMaterials/BBM6.pdf")
                .default
            },
            {
              name:
                "Filip Miketa (Bio-mi Ltd.): Biomaterials success stories: from R&D to production of bio-based and compostable plastics and products",
              presentation: require("@/assets/files/webinars/biobasedMaterials/BBM7.pdf")
                .default
            },
            {
              name:
                "Gianluca Belotti (Mogu S.l.r.): Biofabrication success story: mycelium based materials for different purposes",
              presentation: require("@/assets/files/webinars/biobasedMaterials/BBM8.pdf")
                .default
            },
            {
              name:
                "Jan Palmaers (EXIE – Experts in Healthy Houses): Nature is the best insulator – company presentation",
              presentation: ""
            }
          ],
          embed: "https://www.youtube.com/embed/hPs9s2ICkjc"
        }
      ]
    };
  },
  computed: {
    webinarProps() {
      return {
        title: this.$t("title"),
        date: this.$t("date"),
        intro: this.$t("intro"),
        agenda: Object.values(this.$t("agenda")),
        videoLink: this.videoLink,
        presentationLink: this.videoLink
      };
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";
.webinar {
  background-color: $off_white !important;
  .v-list {
    font-family: $font-family;
  }

  .img {
    width: auto;
    height: 400px;
  }
}
</style>
