<template>
  <Layout>
    <div class="bsat__container webinar">
      <router-link
        text
        to="/training-in-bioeconomy-sectors"
        class="bsat__btn dark-text px-0 font-weight-bold"
      >
        <v-icon left color="dark">mdi-keyboard-backspace</v-icon>
        {{ $t("backToWebinars") }}
      </router-link>
      <v-img
        contain
        class="img my-sm-15"
        src="@/assets/icons/webinars/biochemicals.jpg"
      ></v-img>
      <div class="bsat__title">{{ $t("title") }}</div>
      <div class="bsat__paragraph">{{ $t("intro") }}</div>

      <v-list class="py-0">
        <Webinar
          v-for="(webinar, i) in webinars"
          :key="i"
          v-bind="{ ...webinar }"
        ></Webinar>
      </v-list>
    </div>
  </Layout>
</template>

<i18n>
{
	"en": {
		"backToWebinars": "Back",
		"intro": "Consult the training videos and materials about biochemicals. Experts from the POWER4BIO consortium, related bioeconomy projects, companies from the biochemical sector, research institutes and international agencies give insights about the current status of biochemicals at EU level, innovative practices and keys for successful business cases, value chains, sustainability, funding opportunities and supporting policies. ",
		"title": "Training on Biochemicals"
	}
}
</i18n>

<script>
import Layout from "@/layouts/default";
import Webinar from "@/components/Webinar";
export default {
  name: "BioChemicals",
  metaInfo: {
    title: "Training in Biochemicals"
  },
  components: {
    Layout,
    Webinar
  },
  data() {
    return {
      webinars: [
        // ****************** WEBINAR 1 **********************************
        {
          title:
            "RoadToBio. Roadmap for the chemical industry towards a bioeconomy",
          speakers: [
            "Dr.Ing. Lea König - DECHEMA e.V.",
            "Ms. Yamini Panchaksharam - DECHEMA e.V. and E4tech (UK)"
          ],
          presentation: require("@/assets/files/webinars/biochemicals/BC3.pdf")
            .default,
          embed: "https://www.youtube.com/embed/dFuLxazABBQ"
        },
        {
          title:
            "Bioeconomy Pilot. Interregional cooperation on innovative use of non-food Biomass",
          speakers: ["Ms. Ilaria Re - Consorzio Italbiotec"],
          organization: "Wageningen Food & Biobased Research",
          presentation: require("@/assets/files/webinars/biochemicals/BC4.pdf")
            .default,
          embed: "https://www.youtube.com/embed/Cid2ymCEanM"
        },

        // ***************** WEBINAR 2 **************************
        {
          title:
            "Plant-Based Nutrient Complex with Valuable Amino Acids, Carboxylic Acids, Mineral and Sugars",
          speakers: [
            "Introduction: Dr. Nora Szarka – DBFZ",
            "Best practice example – Biofabrik | The Green© Refinery: Mr. Oliver Riedel (CEO) – Biofabrik Technologies GmbH"
          ],
          presentation: require("@/assets/files/webinars/biochemicals/BC7.pdf")
            .default,
          embed: "https://www.youtube.com/embed/UbJZMS253jc"
        },
        {
          title: "Biorefineries area exemplary R&D projects",
          speakers: ["Arne Grongroft - DBFZ"],
          presentation: require("@/assets/files/webinars/biochemicals/BC8.pdf")
            .default,
          embed: "https://www.youtube.com/embed/IzrQ-Vy3jTg"
        },

        // ***************** WEBINAR 3 ****************************
        {
          title: "Training Webinar 3: Sustainability in biochemical production",
          speakers: [
            "Dr. Alberto Bezama: Helmholtz-Zentrum für Umweltforschung – UFZ",
            "Dr. Almona Tani: Food and Agriculture Organization of the United Nations – FAO, Climate Change Division"
          ],
          presentation: require("@/assets/files/webinars/biochemicals/BC6.pdf")
            .default,
          embed: "https://www.youtube.com/embed/jA1GJQXEyhQ"
        },

        // ******************* WEBINAR 4 **************************
        {
          title:
            "Training Webinar 4: How to finance bio-based chemicals and its value chains using European Structural and Investment Funds",
          speakers: [
            "Introduction: Dr. Nora Szarka – DBFZ",
            "Ms. Francesca Natali: META Group"
          ],
          presentation: require("@/assets/files/webinars/biochemicals/BC1.pdf")
            .default,
          embed: "https://www.youtube.com/embed/yUFyOrH7LL4"
        },

        // ******************* WEBINAR 5 **************************
        {
          title:
            "Training Webinar 5: Supporting policies for bioeconomy – focus on biochemicals",
          speakers: [
            "Dr. Berien Elbersen - Wageningen Food & Biobased Research"
          ],
          presentation: require("@/assets/files/webinars/biochemicals/BC2.pdf")
            .default,
          embed: "https://www.youtube.com/embed/mtlIv0Uea_Q "
        }
      ]
    };
  },
  computed: {
    webinarProps() {
      return {
        title: this.$t("title"),
        date: this.$t("date"),
        intro: this.$t("intro"),
        agenda: Object.values(this.$t("agenda")),
        videoLink: this.videoLink,
        presentationLink: this.videoLink
      };
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";
.webinar {
  background-color: $off_white !important;
  .v-list {
    font-family: $font-family;
  }

  .img {
    width: auto;
    height: 400px;
  }
}
</style>
