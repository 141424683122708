<template>
  <Layout>
    <div class="bsat__container webinar">
      <router-link
        text
        to="/training-in-bioeconomy-sectors"
        class="bsat__btn dark-text px-0 font-weight-bold"
      >
        <v-icon left color="dark">mdi-keyboard-backspace</v-icon>
        {{ $t("backToWebinars") }}
      </router-link>
      <v-img
        contain
        class="img my-sm-15"
        src="@/assets/icons/webinars/bioenergy.jpg"
      ></v-img>
      <div class="bsat__title">{{ $t("title") }}</div>
      <div class="bsat__paragraph">{{ $t("intro") }}</div>

      <v-list class="py-0">
        <Webinar
          v-for="(webinar, i) in webinars"
          :key="i"
          v-bind="{ ...webinar }"
        ></Webinar>
      </v-list>
    </div>
  </Layout>
</template>

<i18n>
{
	"en": {
		"backToWebinars": "Back",
		"intro": "In December 2020, POWER4BIO organised a training webinar series about bioenergy. In a set of three thematic training webinars, experts from the POWER4BIO consortium and other European organisations gave insights about the current status of bioenergy production at EU level, traditional and innovative production technologies, keys for successful business cases, funding opportunities and supporting policies. All webinars were free of charge and open to everyone. In this POWER4BIO training webinar series about bioenergy, we showed success industrial examples and policies and financing instruments needed to success.",
        "title": "Training on BioEnergy"
	}
}
</i18n>

<script>
import Layout from "@/layouts/default";
import Webinar from "@/components/Webinar";
export default {
  name: "BioChemicals",
  metaInfo: {
    title: "Training on Bio Based Materials"
  },
  components: {
    Layout,
    Webinar
  },
  data() {
    return {
      agenda: require("@/assets/files/webinars/biobasedMaterials/Agenda.pdf")
        .default,
      webinars: [
        {
          multiplePresentations: true,
          intro:
            "In this first webinar of the POWER4BIO webinar series on bioenergy, we gave insights to high potential value chains, with a focus on anaerobic digestion and combustion of biomass and residues from agriculture. All steps from conversion technologies to business cases were illuminated.",
          title:
            "Training webinar 1: High potential value chains. From conversion technologies to business cases",
          speakers: [
            {
              name:
                "Alessandro A. Carmona-Martínez (CIRCE): Biogas from agro-residues: farm waste as a resource for bioenergy production",
              presentation: require("@/assets/files/webinars/bioenergy/BIOE1.pdf")
                .default
            },
            {
              name:
                "Manolis Karampinis (CERTH; Coordinator of AgroBioHeat Project): Sustainable and economic rural heating with agro biomass: challenges, technologies and success cases",
              presentation: require("@/assets/files/webinars/bioenergy/BIOE2.pdf")
                .default
            }
          ],
          embed: "https://www.youtube.com/embed/zh6RuKTunA8"
        },
        {
          multiplePresentations: true,
          intro:
            "In the second webinar, we presented successful business cases involving several biomass residues. We highlighted their keys for success and inform about collaboration schemes and gave advice how to develop roadmaps that help promoting the implementation of bioenergy projects.",
          title:
            "Training webinar 2: Successful cases. Keys for success, collaboration schemes and roadmaps to promote the implementation of bioenergy projects",
          speakers: [
            {
              name:
                "Laura Carbó (Ayuntamiento de Vilafranca del Penedès): Project “Viñedos por Calor”",
              presentation: require("@/assets/files/webinars/bioenergy/BIOE3.pdf")
                .default
            },
            {
              name:
                "Tihamer Sebestyen (Green Energy Innovative Cluster): Roadmaps from promotion to implementation of small and medium size bioenergy projects in rural Romania",
              presentation: require("@/assets/files/webinars/bioenergy/BIOE4.pdf")
                .default
            }
          ],
          embed: "https://www.youtube.com/embed/ndFK2Zh26TU"
        },
        {
          multiplePresentations: true,
          intro:
            "In this third and last webinar of the POWER4BIO webinar series on bioenergy, we tackled the needs, limitations and opportunities of policy instruments for the bioenergy sector. We tooke a closer look to policy instruments that facilitate and set requirements to the development of potential value chains.",
          title:
            "Training webinar 3: Legislation and financing opportunities for bioenergy projects",
          speakers: [
            {
              name:
                "Francesca Natali (META Group): How to finance bioenergy companies and its value chain using ESIF. The ingenium ESIF fund: a tool to foster regional economic development",
              presentation: require("@/assets/files/webinars/bioenergy/BIOE5.pdf")
                .default
            },
            {
              name:
                "Alberto Rocamora (Bioenergy Europe Group): The European Green Deal: Bioenergy perspective",
              presentation: require("@/assets/files/webinars/bioenergy/BIOE6.pdf")
                .default
            },
            {
              name:
                "Berien Elbersen (Wageningen Environmental Research): Supporting policies for bioeconomy – focus on Bioenergy",
              presentation: require("@/assets/files/webinars/bioenergy/BIOE7.pdf")
                .default
            }
          ],
          embed: "https://www.youtube.com/embed/2-l1phlyyiw"
        }
      ]
    };
  },
  computed: {
    webinarProps() {
      return {
        title: this.$t("title"),
        date: this.$t("date"),
        intro: this.$t("intro"),
        agenda: Object.values(this.$t("agenda")),
        videoLink: this.videoLink,
        presentationLink: this.videoLink
      };
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";
.webinar {
  background-color: $off_white !important;
  .v-list {
    font-family: $font-family;
  }

  .img {
    width: auto;
    height: 400px;
  }
}
</style>
