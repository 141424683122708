import { render, staticRenderFns } from "./BioBasedMaterials.vue?vue&type=template&id=ce32a422&"
import script from "./BioBasedMaterials.vue?vue&type=script&lang=js&"
export * from "./BioBasedMaterials.vue?vue&type=script&lang=js&"
import style0 from "./BioBasedMaterials.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./BioBasedMaterials.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VImg,VList})
