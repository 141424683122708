<template>
  <div class="bg_off-white webinar">
    <iframe
      v-if="embed"
      width="100%"
      :height="$vuetify.breakpoint.xsOnly ? 200 : 400"
      :src="embed"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <v-list-item dense class="pa-0">
      <v-list-item-content v-if="!multiplePresentations">
        <div class="bsat__subtitle">
          <strong>{{ title }}</strong>
        </div>
        <div class="bsat__paragraph" v-if="intro">{{ intro }}</div>
        <v-list-item-subtitle class="webinar__subtitle">
          <div>
            <strong class="dark-text">
              <v-icon v-if="speakers.length > 1" small color="dark" left
                >mdi-account-multiple</v-icon
              >
              <v-icon v-if="speakers.length === 1" small color="dark" left
                >mdi-account</v-icon
              >
              {{ $tc("speaker", speakers.length) }}:
            </strong>
            <span v-if="speakers.length === 1">{{ speakers[0] }}</span>
            <ul v-if="speakers.length > 1">
              <li v-for="(speaker, i) in speakers" :key="i">{{ speaker }}</li>
            </ul>
          </div>
        </v-list-item-subtitle>

        <div class="mt-2">
          <span v-if="video">
            <a :href="video" target="_blank" rel="noopener noreferrer">
              {{ $t("accessToFullVideo") }}
            </a>
          </span>
          <span v-if="video && presentation">|</span>
          <span v-if="presentation">
            <a :href="presentation" target="_blank" download>
              {{ $t("presentation") }}
            </a>
          </span>
          <div v-else></div>
        </div>
      </v-list-item-content>

      <v-list-item-content v-else>
        <div class="bsat__subtitle">
          <strong>{{ title }}</strong>
        </div>
        <div class="bsat__paragraph" v-if="intro">{{ intro }}</div>
        <v-list-item-subtitle class="webinar__subtitle">
          <div>
            <strong class="dark-text">
              <v-icon small color="dark" left>mdi-account-multiple</v-icon>
              {{ $tc("speaker", speakers.length) }}:
            </strong>
            <ul>
              <li v-for="(speaker, i) in speakers" :key="i" class="my-2">
                <div>{{ speaker.name }}</div>
                <div>
                  <a
                    v-if="speaker.presentation"
                    :href="speaker.presentation"
                    target="_blank"
                    download
                  >
                    {{ $t("presentation") }}
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </v-list-item-subtitle>

        <!-- <div class="mt-2">
          <span v-if="video">
            <a :href="video" target="_blank" rel="noopener noreferrer">
              {{ $t("accessToFullVideo") }}
            </a>
          </span>
          <span v-if="video && presentation">|</span>
          <span v-if="presentation">
            <a :href="presentation" target="_blank" download>
              {{ $t("presentation") }}
            </a>
          </span>
          <div v-else></div>
        </div> -->
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<i18n>
{
	"en": {
		"accessToFullVideo": "Video",
		"presentation": "Download presentation",
		"speaker": "Speaker | Speakers"
	}
}
</i18n>

<script>
export default {
  props: {
    title: String,
    intro: String,
    speakers: Array,
    organization: String,
    video: String,
    presentation: String,
    embed: String,
    multiplePresentations: Boolean
  },
  computed: {
    webinar__title() {
      return {
        "font-size": this.$vuetify.breakpoint.mdAndUp
          ? "18px!important"
          : "14px!important",
        "flex-wrap": "wrap",
        "text-overflow": "clip !important",
        "white-space": "normal !important",
        "letter-spacing": "normal",
        "line-height": "1.6"
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/main";
.webinar {
  padding: 40px 0px !important;
  // border: 1px solid $light_green;
  // border-radius: 8px;

  // &__title {
  //   font-size: 16px;
  //   flex-wrap: wrap;
  //   text-overflow: clip !important;
  //   white-space: normal !important;
  //   letter-spacing: normal;
  //   line-height: normal;
  // }

  .v-list-item__title {
    text-overflow: clip !important;
    white-space: normal !important;
    letter-spacing: normal;
    line-height: normal;
    font-size: 18px;
  }

  &__subtitle {
    text-overflow: clip !important;
    white-space: normal !important;
    letter-spacing: normal;
    line-height: normal;
    padding: 10px 0px;
    font-size: 14px;
  }
}
.img {
  width: auto;
  height: 400px;
}
</style>
